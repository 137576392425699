import React, { useState } from 'react';
import { styled } from 'twin.macro';
import { Link } from 'react-router-dom';

import { motion } from '@ubisend/framer-motion';
import {
  Flex,
  Span,
  Button,
  useTheme,
  InnerPanel,
  Placeholder,
  PanelSlider,
  Sidebar as PulseSideBar,
  SidebarItem as PulseSideBarItem
} from '@ubisend/pulse-components';
import {
  useAuth,
  hasFeature,
  hasPermission,
  GlobalRoleFilter,
  hasSomePermissions,
  hasActiveFeatureGate,
  GlobalFeatureGateFilter
} from '@ubisend/pulse-auth';
import Icon from '@ubisend/pulse-icons';
import { ClientCard } from '@ubisend/pulse-clients';
import { useQuery } from '@ubisend/pulse-hooks';

import LicenseStatus from '../LicenseStatus';
import SidebarItem from './SidebarItem';
import { useSidebar } from '../../hooks/index';

const Image = styled.img`
  width: 75%;
  height: 100%;
`;

const containerAnim = {
  show: {
    transition: {
      staggerChildren: 0.05
    }
  }
};

const links = [
  {
    to: '/home',
    label: 'Home',
    icon: 'home'
  },
  {
    to: '/dashboard',
    label: 'Dashboard',
    icon: 'chartBar',
    canSee: [hasPermission('view metrics')]
  },
  {
    to: '/builder',
    label: 'Builder',
    canSee: [
      hasActiveFeatureGate('builder'),
      hasPermission('view conversations')
    ],
    icon: 'cubeTransparent'
  },
  {
    label: 'Engage',
    icon: 'eye',
    items: [
      {
        to: '/broadcast',
        label: 'Broadcast',
        canSee: [hasActiveFeatureGate('broadcasts')]
      },
      {
        label: 'Channels',
        to: '/channels',
        canSee: [hasPermission('view channels')]
      },
      {
        label: 'Composers',
        to: '/composers',
        canSee: [hasPermission('view composers')]
      },
      {
        to: '/notifications',
        label: 'Notifications',
        canSee: [hasPermission('view notifications')]
      },
      {
        to: '/links',
        label: 'Links',
        canSee: [hasPermission('view links')]
      }
    ]
  },
  {
    label: 'Knowledge',
    icon: 'lightBulb',
    items: [
      {
        to: '/faqs',
        label: 'FAQs',
        canSee: [hasPermission('view faqs')]
      },
      {
        to: '/knowledge-bases',
        label: 'Knowledge Bases',
        canSee: [
          hasActiveFeatureGate('knowledge bases'),
          hasPermission('view knowledge bases')
        ]
      },
      {
        to: '/smalltalk',
        label: 'Small Talk',
        canSee: [
          hasActiveFeatureGate('small talk'),
          hasFeature('small talk'),
          hasPermission('view small talk')
        ]
      },
      {
        to: '/training',
        label: 'Training',
        canSee: [hasPermission('view training messages')]
      },
      {
        to: '/semantic-search/explore',
        label: 'Semantic Searches',
        canSee: [
          hasActiveFeatureGate('semantic search'),
          hasPermission('view knowledge bases')
        ]
      },
      {
        to: '/generative-ai',
        label: 'Generative AI',
        canSee: [
          hasActiveFeatureGate('generative ai'),
          hasPermission('view generative ai')
        ]
      }
    ]
  },
  {
    label: 'Service',
    icon: 'ticket',
    items: [
      {
        to: '/feedback',
        label: 'Feedback',
        canSee: [
          hasActiveFeatureGate('feedback'),
          hasPermission('view feedback')
        ]
      },
      {
        to: '/live-chat',
        label: 'Live Chat',
        canSee: [
          hasActiveFeatureGate('live chats'),
          hasPermission('view tickets')
        ]
      },
      {
        to: '/tickets/agents/me',
        label: 'Ticketing',
        canSee: [
          hasActiveFeatureGate('live chats'),
          hasPermission('view tickets')
        ]
      }
    ]
  },
  {
    label: 'Analyse',
    icon: 'documentSearch',
    items: [
      {
        to: '/conversations',
        label: 'Conversations',
        canSee: [hasPermission('view subscribers')]
      },
      {
        to: '/custom-metrics',
        label: 'Custom Metrics',
        canSee: [hasPermission('view custom metrics')]
      },
      {
        to: '/regression-testing',
        label: 'FAQ testing',
        canSee: [hasFeature('faq regression testing')]
      },
      {
        to: '/logs',
        label: 'Logs',
        canSee: [hasPermission('view logs')]
      }
    ]
  },
  {
    to: '/integrations',
    label: 'Integrations',
    icon: 'chip',
    canSee: [
      hasActiveFeatureGate('integrations'),
      hasSomePermissions('view integrations', 'view spreadsheets')
    ]
  },
  {
    label: 'Settings',
    icon: 'adjustments',
    items: [
      {
        to: '/blocklist',
        label: 'Block list',
        canSee: [hasPermission('view block list')]
      },
      {
        to: '/compliance',
        label: 'Compliance',
        canSee: [hasPermission('view compliance')]
      },
      {
        to: '/emails',
        label: 'Emails',
        canSee: [hasActiveFeatureGate('emails'), hasPermission('view emails')]
      },
      {
        to: '/fallback',
        label: 'Fallback',
        canSee: [hasPermission('view human fallback')]
      },
      {
        to: '/files',
        label: 'Files',
        canSee: [hasPermission('view files')]
      },
      {
        to: '/languages',
        label: 'Languages',
        canSee: [
          hasActiveFeatureGate('languages'),
          hasPermission('deactivate languages')
        ]
      },
      {
        to: '/locations',
        label: 'Locations',
        canSee: [hasPermission('edit locations')]
      },
      {
        to: '/nlp',
        label: 'NLP',
        canSee: [hasPermission('view nlp providers')]
      },
      {
        to: '/semantic-search',
        label: 'Semantic Search',
        canSee: [
          hasActiveFeatureGate('semantic search'),
          hasPermission('view nlp providers')
        ]
      },
      {
        to: '/payments',
        label: 'Payments',
        canSee: [
          hasActiveFeatureGate('payments'),
          hasPermission('view payment providers')
        ]
      },
      {
        to: '/tags',
        label: 'Tags',
        canSee: [hasPermission('view tags')]
      },
      {
        to: '/users',
        label: 'Users',
        canSee: [hasPermission('view users')]
      },
      {
        to: '/variables',
        label: 'Variables',
        canSee: [hasPermission('view variables')]
      }
    ]
  },
  {
    icon: 'chat',
    to: '/demo',
    label: 'Demo',
    canSee: [hasPermission('view demo')]
  },
  {
    to: '/docs',
    label: 'Documentation',
    icon: 'bookOpen',
    canSee: [hasPermission('view documentation')]
  }
];

const ClientSidebar = () => {
  const [slideout, setSlideout] = useState(false);

  const auth = useAuth();
  const theme = useTheme();

  const clients = useQuery(['clients/all']);

  const { filteredLinks, openLinkIndex, handleOpen } = useSidebar(links);

  const openClientSwitcher = () => setSlideout(true);

  const closeClientSwitch = () => {
    setSlideout(false);
  };

  return (
    <>
      <PulseSideBar
        aria-label="main-sidebar"
        type="client"
        style={{ pointerEvents: 'auto' }}>
        {auth.client.logo && (
          <Flex middle fat>
            <Image src={auth.client.logo} alt={auth.client.name} />
          </Flex>
        )}
        <Flex grow fat xHidden col yScroll>
          <Flex padSm col between grow ySpace>
            <motion.div variants={containerAnim} initial="hide" animate="show">
              {filteredLinks.map((link, key) => (
                <SidebarItem
                  key={`${key}-${link.label}`}
                  type="client"
                  open={Boolean(openLinkIndex && openLinkIndex === key)}
                  handleOpen={handleOpen(key)}
                  {...link}
                />
              ))}
            </motion.div>
            <Flex col fat>
              <GlobalFeatureGateFilter for="owner area">
                <GlobalRoleFilter for="owner">
                  <PulseSideBarItem type="client">
                    <Link to="/owner/accounts">
                      <Flex center xSpaceSm>
                        <Icon
                          type="switchHorizontal"
                          colour={theme.secondary}
                          width="1.25rem"
                          height="1.25rem"
                        />
                        <Span>Owner</Span>
                      </Flex>
                    </Link>
                  </PulseSideBarItem>
                </GlobalRoleFilter>
              </GlobalFeatureGateFilter>
              <PulseSideBarItem type="client">
                <button
                  onClick={slideout ? closeClientSwitch : openClientSwitcher}>
                  <Flex middle center>
                    <Flex xSpaceSm middle center>
                      <Icon
                        type="user"
                        colour={theme.secondary}
                        width="1.25rem"
                        height="1.25rem"
                      />
                      <Span noWrap>Accounts</Span>
                    </Flex>
                  </Flex>
                </button>
              </PulseSideBarItem>
              <PulseSideBarItem type="client">
                <Link to="/logout">
                  <Flex center xSpaceSm>
                    <Icon
                      type="logout"
                      colour={theme.secondary}
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <Span>Logout</Span>
                  </Flex>
                </Link>
              </PulseSideBarItem>
            </Flex>
          </Flex>
          <LicenseStatus />
        </Flex>
      </PulseSideBar>
      {slideout && (
        <PanelSlider
          handleHide={closeClientSwitch}
          divider
          from="left"
          width="25rem"
          header="Switch Account">
          <Flex col ySpace>
            {clients.isSuccess && clients.data.data.length === 0 && (
              <Placeholder />
            )}
            {!clients.isSuccess && clients.isLoading && <Placeholder />}
            {clients.isSuccess &&
              clients.data.data.length > 0 &&
              clients.data.data.map((client, key) => (
                <InnerPanel key={key} active={client.id === auth.client.id}>
                  <Flex fat>
                    <Flex style={{ width: '90%' }}>
                      <ClientCard client={client} />
                    </Flex>
                    <Flex middle center>
                      <Button
                        as={Link}
                        variant="inline"
                        aria-label={`Switch to ${client.name}`}
                        onClick={() => setSlideout(false)}
                        to={
                          client.id === auth.client.id
                            ? '#'
                            : `/accounts/${client.id}/switch`
                        }
                        disabled={client.id === auth.client.id}
                        icon={
                          client.id === auth.client.id
                            ? 'checkCircle'
                            : 'switchHorizontal'
                        }
                      />
                    </Flex>
                  </Flex>
                </InnerPanel>
              ))}
          </Flex>
        </PanelSlider>
      )}
    </>
  );
};

export default ClientSidebar;
