import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import Label from '../Forms/Label';
import { spacing } from '../styles';

const StyledLabel = styled.label`
  ${spacing}

  ${tw`flex border-0 bg-transparent p-0`}
  ${props => props.disabled && tw`opacity-50`}
`;

const PulseRadio = ({
  checked,
  onChange,
  label,
  disabled,
  'aria-label': ariaLabel,
  className,
  value,
  name
}) => {
  return (
    <StyledLabel
      disabled={disabled}
      className={className}
      htmlFor={ariaLabel || label}>
      <input
        id={ariaLabel || label}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        aria-label={ariaLabel}
        name={name}
      />
      <Label mb={false} htmlFor={ariaLabel || label}>
        <span>{label}</span>
      </Label>
    </StyledLabel>
  );
};

PulseRadio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  'aria-label': PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string
};

const StyledRadio = styled(PulseRadio)`
  ${tw`relative`}
  & > input {
    ${tw`absolute opacity-0 top-0 left-0 m-0`}
    height: 24px;
    width: 24px;
    & + label {
      height: 24px;
      ${tw`inline-flex items-center relative cursor-pointer p-0 w-full`}
      & > span {
        ${tw`inline-block `}
      }
    }

    /* Box */
    & + label:before {
      ${tw`mr-2 inline-block align-middle rounded-full border-solid border-grey border`}
      content: '';
      border: 1px solid
        ${({ theme, colour }) => {
          return chroma(colour || theme.primary).alpha(0.5);
        }};
      width: 20px;
      height: 20px;
    }

    &:focus + label:before,
    &:hover + label:before {
      border: 1px solid
        ${({ theme, colour, custom_colour }) => {
          const bgColour = custom_colour || theme[colour] || theme.primary;
          return chroma(bgColour).alpha(0.5);
        }};
      box-shadow: 0 0 0 0.2rem
        ${({ theme, colour, custom_colour }) => {
          const bgColour = custom_colour || theme[colour] || theme.primary;
          return chroma(bgColour).alpha(0.25);
        }};
    }

    &:checked + label:after {
      ${tw`absolute`}
      color:${({ theme, colour }) => {
        return chroma(colour || theme.primary);
      }};
      content: '⬤';
      left: 4px;
      top: 3px;
      font-size: 12px;
    }
  }
`;

export default StyledRadio;
