import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import {
  ConverseSettings,
  FullPageSettings,
  EmbedSettings
} from './Pages/index';

const routes = [
  {
    name: 'Converse',
    path: '/converse',
    component: ConverseSettings,
    canAccess: [
      hasActiveFeatureGate('channel - converse'),
      hasPermission('view converse settings')
    ],
    actions: [
      {
        title: 'Manage your Converse channel',
        description: 'Design your Converse widget.',
        icon: 'colorSwatch'
      }
    ]
  },
  {
    name: 'Embedded',
    path: '/embedded',
    component: EmbedSettings,
    canAccess: [
      hasActiveFeatureGate('channel - embeded'),
      hasPermission('view embed settings')
    ],
    actions: [
      {
        title: 'Manage your Embedded channel',
        description: 'Design your Embedded chatbot.',
        icon: 'colorSwatch'
      }
    ]
  },
  {
    name: 'Full Page',
    path: '/full-page',
    component: FullPageSettings,
    canAccess: [
      hasActiveFeatureGate('channel - full page'),
      hasPermission('view full page settings')
    ],
    actions: [
      {
        title: 'Manage your Full Page channel',
        description: 'Design your Full Page chatbot.',
        icon: 'colorSwatch'
      }
    ]
  }
];

export default routes;
