import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { Broadcast, QueuedBroadcasts } from './Pages/index';

const routes = [
  {
    path: '/broadcast',
    component: Broadcast,
    name: 'Broadcasts',
    canAccess: [
      hasActiveFeatureGate('broadcasts'),
      hasPermission('create broadcasts')
    ]
  },
  {
    path: '/broadcast/scheduled',
    component: QueuedBroadcasts,
    name: 'Your scheduled broadcasts',
    canAccess: [
      hasActiveFeatureGate('broadcasts'),
      hasPermission('view broadcasts')
    ],
    actions: [
      {
        title: 'Manage your scheduled broadcasts',
        description: 'Create, update and delete your scheduled broadcasts.',
        icon: 'speakerphone'
      }
    ]
  }
];

export default routes;
