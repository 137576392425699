import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex } from '@ubisend/pulse-components';

import FacebookIcon from './FacebookIcon';

const defaultText = (
  <Flex center xSpace>
    <FacebookIcon style={{ marginRight: '0.5rem' }} inverted />
    Connect to Facebook
  </Flex>
);

const loadingText = (
  <Flex center xSpace>
    <FacebookIcon style={{ marginRight: '0.5rem' }} inverted />
    Connecting
  </Flex>
);

const LoginButton = ({ loading, ...props }) => (
  <Button
    custom_colour="#1877f2"
    aria-label="fb-login-button"
    value="login"
    loading={loading}
    {...props}>
    {loading ? loadingText : defaultText}
  </Button>
);

LoginButton.propTypes = {
  loading: PropTypes.bool
};

export default LoginButton;
