import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import chroma from 'chroma-js';

import Icon, { outlineIcons, solidIcons } from '@ubisend/pulse-icons';

import { Spinner } from '../Loading/index';
import { useTheme } from '../../hooks/index';
import { flex, spacing, size, position, text } from '../styles';
import { pseudoStyles } from '../Input/TextInput';

const getGradient = (colour, darken = 0) => {
  if (!colour) {
    return;
  }
  return `linear-gradient(${chroma(colour).brighten(0.5 - darken)},${chroma(
    colour
  ).darken(darken)})`;
};

const baseTextStyles = css`
  & > *,
  & * {
    ${tw`font-poppins`}
  }
  ${tw`px-3 py-2 text-xs leading-tight uppercase font-poppins self-center truncate`}
  letter-spacing: 0.05em;
  padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
  ${text}
`;

const primaryTextStyles = css`
  color: ${props => props.theme.white};
  ${text}
`;

const secondaryTextStyles = css`
  ${tw`font-normal`}
  ${({ theme, colour, custom_colour }) => {
    return `color: ${custom_colour || theme[colour] || theme.primary};`;
  }}
`;

const baseButtonStyles = css`
  box-sizing: border-box;
  ${tw`border-0 text-center cursor-pointer outline-none rounded-sm flex h-8 flex-shrink-0 no-underline w-auto`}
  ${props => {
    return props.disabled ? tw`cursor-not-allowed opacity-50` : '';
  }}
  & i:not(:only-child) {
    ${tw`mr-2`}
  }
  & svg {
    width: 14px;
    height: 14px;
  }
`;

const primaryButtonStyles = css`
  background: ${({ theme, colour, custom_colour }) => {
    return getGradient(custom_colour || theme[colour] || theme.primary);
  }};
  &:not(:disabled):hover {
    background: ${({ theme, colour, custom_colour }) => {
      return getGradient(custom_colour || theme[colour] || theme.primary, 0.25);
    }};
  }
  &:not(:disabled):active {
    background: ${({ theme, colour, custom_colour }) => {
      return getGradient(custom_colour || theme[colour] || theme.primary, 0.5);
    }};
  }
  border: 1px
    ${({ theme, colour, custom_colour }) => {
      return chroma(custom_colour || theme[colour] || theme.primary).darken(
        0.125
      );
    }}
    solid;
`;

const secondaryButtonStyles = css`
  background: white;
  ${({ theme, colour, custom_colour }) => {
    return `border: 1px solid ${chroma(
      custom_colour || theme[colour] || theme.primary
    ).alpha(0.5)};`;
  }}
  &:not(:disabled):hover {
    background: ${({ theme, colour, custom_colour }) => {
      return chroma(custom_colour || theme[colour] || theme.primary).alpha(
        0.05
      );
    }};
  }
  &:not(:disabled):active {
    background: ${({ theme, colour, custom_colour }) => {
      return chroma(custom_colour || theme[colour] || theme.primary).alpha(0.1);
    }};
  }
  ${pseudoStyles}
`;

const inlineButtonStyles = css`
  ${tw`bg-transparent h-auto`}
  border: 1px solid transparent;
  color: ${({ theme, colour }) => (colour ? theme[colour] : theme.primary)};
  letter-spacing: 0.05em;
  &:not(:disabled):hover {
    color: ${({ theme, colour }) =>
      chroma(theme[colour] || theme.primary).alpha(0.75)};
  }
`;

const buttonStyles = {
  primary: primaryButtonStyles,
  secondary: secondaryButtonStyles,
  inline: inlineButtonStyles
};

const textStyles = {
  primary: primaryTextStyles,
  secondary: secondaryTextStyles,
  inline: ''
};

const StyledButton = styled.button`
  ${baseButtonStyles}
  ${baseTextStyles}
  ${props => buttonStyles[props.variant]}
  ${props => textStyles[props.variant]}
  ${flex}
  ${spacing}
  ${size}
  ${pseudoStyles}
  ${position}
`;

const Button = React.forwardRef(
  (
    {
      variant = 'secondary',
      colour = 'primary',
      custom_colour,
      icon,
      loading,
      type = 'button',
      disabled,
      as,
      children,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const spinnerColour = variant === 'primary' ? 'white' : theme[colour];

    // If we can't find an outline icon, try use a solid
    const outline = outlineIcons[icon] ? true : false;

    return (
      <StyledButton
        ref={ref}
        as={as}
        variant={variant}
        disabled={disabled || loading}
        custom_colour={custom_colour}
        colour={colour}
        center="true"
        type={type}
        {...props}>
        {loading ? (
          <i>
            <Spinner firstColour={spinnerColour} secondColour={spinnerColour} />
          </i>
        ) : (
          icon && (
            <Icon
              outline={outline}
              type={icon}
              height="0.875rem"
              width="0.875rem"
              size="0.875rem"
            />
          )
        )}
        {children && <span>{children}</span>}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'inline']),
  colour: PropTypes.string,
  custom_colour: PropTypes.string,
  icon: PropTypes.oneOf([
    ...new Set([...Object.keys(outlineIcons), ...Object.keys(solidIcons)])
  ]),
  loading: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
export { StyledButton };
