import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  PageWrapper,
  StretchPanel,
  Table,
  TableBody,
  TableCell,
  useModal,
  useNotification,
  TableHead,
  TableRow,
  TableActions,
  Button,
  EmptyStatePlaceholder,
  NumberFormatter,
  OrderableTableRow,
  Pagination,
  useOrderableTableReducer,
  usePaginationReducer
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import {
  useLanguages,
  useQuery,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';

import { deleteNotification } from '../api/index';

const Notifications = () => {
  const order = useOrderableTableReducer({ id: 'notifications' });
  const pagination = usePaginationReducer({ id: 'notifications' });

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { defaultLanguage } = useLanguages();

  const queryClient = useQueryClient();
  const query = useQuery([
    'template-notifications',
    { ...order.params, ...pagination.params }
  ]);
  const mutation = useMutation(deleteNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('template-notifications');
      showSuccess('Notification deleted sucessfully');
    }
  });

  const handleDelete = ({ id }) => {
    showModal({
      header: 'Delete Notification',
      body: `Are you sure you want to delete this notification?`,
      handleConfirm: async () => {
        await mutation.mutateAsync(id);
        hideModal();
      }
    });
  };

  return (
    <PageWrapper
      header="Notifications"
      subheader="Create, edit and delete notifications"
      actions={
        query.isSuccess && !query.showNoResultsMessage ? (
          <PermissionFilter can="create notifications">
            <Button
              icon="plus"
              variant="primary"
              as={Link}
              to="/notifications/create">
              New Notification
            </Button>
          </PermissionFilter>
        ) : (
          <></>
        )
      }>
      <StretchPanel>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="alerts"
            heading="Create a notification"
            text="Notify your chatbot users with persistent and non-intrusive widget notification."
            actions={
              <PermissionFilter can="create notifications">
                <Button variant="primary" as={Link} to="/notifications/create">
                  + New Notification
                </Button>
              </PermissionFilter>
            }
            helpLink="/docs/2151317744/2153578535"
            helpText="Learn more about notifications."
          />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={6}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Notification', sort: null },
                    { label: 'Link', sort: null },
                    { label: 'Sent', sort: null },
                    { label: 'Opens', sort: null },
                    { label: 'Created at', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((notification, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        {
                          notification.bodies.find(
                            body => body.language_id === defaultLanguage.id
                          ).content.body
                        }
                      </TableCell>
                      <TableCell>
                        {notification.links.find(
                          link => link.language_id === defaultLanguage.id
                        ).content.link || 'N/A'}
                      </TableCell>
                      <TableCell>
                        {notification.notification_count ? (
                          <NumberFormatter>
                            {notification.notification_count}
                          </NumberFormatter>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>
                        {notification.read_count ? (
                          <NumberFormatter>
                            {notification.read_count}
                          </NumberFormatter>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>
                        {dayjs(notification.created_at).format(
                          'D MMM YYYY HH:mm'
                        )}
                      </TableCell>
                      <TableActions>
                        <PermissionFilter can="edit notifications">
                          <Button
                            icon="pencilAlt"
                            as={Link}
                            variant="secondary"
                            to={`/notifications/${notification.id}`}>
                            Edit
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter can="delete notifications">
                          <Button
                            colour="danger"
                            variant="secondary"
                            icon="trash"
                            onClick={() => handleDelete(notification)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default Notifications;
