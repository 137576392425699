import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Icon from '@ubisend/pulse-icons';
import {
  Flex,
  Span,
  Label,
  Button,
  Divider,
  Heading3,
  Indicator,
  TextInput,
  InnerPanel,
  NumberFormatter
} from '@ubisend/pulse-components';
import {
  FeatureFilter,
  PermissionFilter,
  GlobalFeatureGateFilter
} from '@ubisend/pulse-auth';
import { CustomVariablePreview } from '@ubisend/pulse-integrations';

const TicketStatus = ({ status }) => {
  const statuses = {
    submitted: 'danger',
    in_progress: 'warning',
    resolved: 'positive'
  };
  return <Indicator colour={statuses[status]} />;
};

TicketStatus.propTypes = {
  status: PropTypes.string
};

const Container = styled(Flex)`
  height: calc(100% - 4rem);

  overflow-y: auto;
`;

const textFormat = value => {
  const emailChecker = /([a-zA-Z0-9._%+-]+@+[a-zA-Z0-9.-]+\.+[a-zA-Z]{2,4})/;
  const linkChecker = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

  if (emailChecker.test(value)) {
    return 'email';
  } else if (linkChecker.test(value)) {
    return 'link';
  }
  return 'standard';
};

const SubscriberDetails = ({
  subscriber,
  ticket,
  actions,
  hideConversation
}) => {
  return (
    <Container col ySpace fat id="subscriber-details">
      <Flex mb center fat>
        <Heading3>Subscriber information</Heading3>
      </Flex>
      <Flex col fat>
        <InnerPanel>
          <Flex col ySpace relative>
            <Flex fat>
              <Flex col fat>
                <Label htmlFor="id">id</Label>
                <TextInput id="id" disabled readOnly value={subscriber.id} />
              </Flex>
            </Flex>
            {subscriber.channel && (
              <Flex col>
                <Label htmlFor="channel_driver">Channel</Label>
                <TextInput
                  id="channel_driver"
                  disabled
                  readOnly
                  value={subscriber.channel.driver.name}
                />
              </Flex>
            )}
            {subscriber.created_at && (
              <Flex col>
                <Label htmlFor="created_at">created at</Label>
                <TextInput
                  id="created_at"
                  disabled
                  readOnly
                  value={dayjs(subscriber.created_at).format(
                    'HH:mm DD/MM/YYYY'
                  )}
                />
              </Flex>
            )}
            {subscriber.last_active_at && (
              <Flex col>
                <Label htmlFor="last_active_at">last active at</Label>
                <TextInput
                  id="last_active_at"
                  disabled
                  readOnly
                  value={dayjs(subscriber.last_active_at).format(
                    'HH:mm DD/MM/YYYY'
                  )}
                />
              </Flex>
            )}
            <GlobalFeatureGateFilter for="sentiment analysis">
              {subscriber.sentiment_first && (
                <Flex col>
                  <Label htmlFor="sentiment_first">First Sentiment</Label>
                  <TextInput
                    id="sentiment_first"
                    disabled
                    readOnly
                    value={subscriber.sentiment_first}
                  />
                </Flex>
              )}
              {subscriber.sentiment_average && (
                <Flex col>
                  <Label htmlFor="sentiment_average">
                    Average Sentiment Score
                  </Label>
                  <TextInput
                    id="sentiment_average"
                    disabled
                    readOnly
                    value={subscriber.sentiment_average}
                  />
                </Flex>
              )}
            </GlobalFeatureGateFilter>
            <GlobalFeatureGateFilter for="authorisation">
              <FeatureFilter feature="authorisation">
                <Flex col>
                  <Label htmlFor="authorisation">Authorisation status</Label>
                  <TextInput
                    id="authorisation"
                    disabled
                    readOnly
                    value={
                      subscriber.authorised_at ? 'Authorised' : 'Not authorised'
                    }
                  />
                </Flex>
              </FeatureFilter>
            </GlobalFeatureGateFilter>
            {actions && actions}
          </Flex>
        </InnerPanel>
      </Flex>
      {Object.entries(subscriber.variables).length > 0 && (
        <>
          <Flex mb>
            <Heading3>Variables</Heading3>
          </Flex>
          <InnerPanel>
            <Flex col ySpace>
              {subscriber.variables.map(variable => (
                <Flex col key={variable.id}>
                  <Label>{variable.name.split('_').join(' ')}</Label>
                  <Flex xScroll>
                    <Span smallText style={{ whiteSpace: 'pre' }}>
                      <CustomVariablePreview
                        variable={{
                          type: variable.type,
                          default_value: variable.value,
                          variable_format: textFormat(variable.value)
                        }}
                      />
                    </Span>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </InnerPanel>
        </>
      )}
      {subscriber.tickets.length > 0 && (
        <>
          <Flex mb>
            <Heading3>Tickets</Heading3>
          </Flex>
          <InnerPanel>
            {subscriber.tickets.map(({ id, name, status }, i) => (
              <React.Fragment key={id}>
                {i !== 0 && <Divider shiftLeft shiftRight pr pl />}
                <Flex between>
                  <Flex xSpaceSm center>
                    <TicketStatus status={status} />
                    <span>{name}</span>
                  </Flex>
                  <PermissionFilter can="view tickets">
                    <Button
                      as={Link}
                      variant="secondary"
                      to={id === ticket?.id ? '#' : `/tickets/${id}`}
                      disabled={id === ticket?.id}
                      icon="eye">
                      {id === ticket?.id ? (
                        <span>Current ticket</span>
                      ) : (
                        <span>View ticket</span>
                      )}
                    </Button>
                  </PermissionFilter>
                </Flex>
              </React.Fragment>
            ))}
          </InnerPanel>
        </>
      )}
      {subscriber.files_count > 0 && (
        <>
          <Flex mb>
            <Heading3>Files</Heading3>
          </Flex>
          <InnerPanel>
            <Flex between>
              <Flex center xSpaceSm>
                <Icon
                  size="1.5rem"
                  height="1.5rem"
                  width="1.5rem"
                  outline
                  type="document"
                />
                <Label mb={false}>
                  <NumberFormatter>{subscriber?.files_count}</NumberFormatter>{' '}
                  File
                  {subscriber?.files_count !== 1 && 's'}
                </Label>
              </Flex>
              <PermissionFilter can="view messages">
                <Button
                  as={Link}
                  variant="secondary"
                  to={`/files?subscriber_id=${subscriber.id}`}
                  icon="eye"
                  target="_blank">
                  View
                </Button>
              </PermissionFilter>
            </Flex>
          </InnerPanel>
        </>
      )}
      {!hideConversation && (
        <>
          <Flex mb>
            <Heading3>Conversation</Heading3>
          </Flex>
          <InnerPanel>
            <Flex between>
              <Flex center xSpaceSm>
                <Icon
                  size="1.5rem"
                  height="1.5rem"
                  width="1.5rem"
                  outline
                  type="chatAlt"
                />
                <Label mb={false}>
                  <NumberFormatter>{subscriber.message_count}</NumberFormatter>{' '}
                  Message
                  {subscriber.message_count !== 1 && 's'}
                </Label>
              </Flex>
              <PermissionFilter can="view messages">
                <Button
                  as={Link}
                  variant="secondary"
                  to={`/conversations/${subscriber.id}`}
                  icon="eye">
                  View
                </Button>
              </PermissionFilter>
            </Flex>
          </InnerPanel>
        </>
      )}
    </Container>
  );
};

SubscriberDetails.propTypes = {
  subscriber: PropTypes.object,
  ticket: PropTypes.object,
  actions: PropTypes.element,
  hideConversation: PropTypes.bool
};

export default SubscriberDetails;
export { textFormat };
